body {
  font-size: 18px;
  line-height: 1.8;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
p,
a {
  @include transition();
}

strong {
  font-weight: 900;
}
