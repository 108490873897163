body {
  color: $body-color;
}

section {
  padding: 30px 0;
  @include media-breakpoint-up(md) {
    padding: 60px 0;
  }
}

@include media-breakpoint-down(sm) {
  br {
    display: none;
  }
}
