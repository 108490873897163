// Fonts
// @import url("https://fonts.googleapis.com/css?family=Montserrat:100,300,400,700,Spectral:400,400i,500i,600,600i&display=swap");


@import "settings";
@import "vendor/bootstrap/scss/bootstrap"; // Copied from node_modules and committed to source to allow Bootstrap to compile during build

// Global
@import "global/mixins";
@import "global/utilities";
@import "global/base";
@import "global/layout";
@import "global/typography";
@import "global/animations";

// Vendor Specific
@import "vendor/bootstrap";
@import "vendor/cognito";


